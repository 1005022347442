import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import photo_q from '../assets/images/q.png';
import photo_roman from '../assets/images/roman_kuzmenko.jpg';
import photo_anton from '../assets/images/anton_seidler.jpg';
import photo_pavel from '../assets/images/pavel_khijniak.jpg';
import photo_shehab from '../assets/images/shehab_attia.jpg';
import photo_aleksandr from '../assets/images/aleksandr_ilin.jpg';
import photo_victor from '../assets/images/victor_de_wansa.png';
import photo_denis from '../assets/images/denis_alkhazov.jpg';
import photo_laert from '../assets/images/laert_karaashev.jpg';
import photo_noman from '../assets/images/noman_ahmed.png';

function About() {
  const style_container = {
    paddingTop: "0px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
  };
  const style_card = {
    margin: "15px",
    width: '18rem',
    // border: "none",
  };
  const style_body = {
    padding: "25px",
    // backgroundColor: "#282c34",
  };
  const style_image = {
    paddingBottom: "20px",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    fontWeight: 1200,
  };
  const style_text = {
    paddingTop: "20px",
    fontSize: 16,
    color: '#ccc',
  };
  const style_link = {
    display: 'block',
    fontSize: 16,
    color: '#ccc',
    // textDecoration: "none",
  };

  return (
    <div className="App-view">
      <div style={{ marginLeft: 20, marginRight: 20, textAlign: "center" }}>
        PartCAD is a former component of <Link to="https://github.com/openvmp/openvmp">OpenVMP</Link>.
        <br/>
        It has its own life now.<br/>
        <br/>
        We're a passionate team in the pre-seed phase,
        developing a groundbreaking solution for collaboration on CAD designs.
        <br />
        We're actively exploring various funding options.<br />
        Come shape the future of engineering together with us!<br/>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginTop: 10, marginBottom: 40}} href="https://calendly.com/partcad-support/30min">Reach out!</Button>
      </div>
      {/*<Container style={style_container}>*/}
      <Row style={style_container}>
        {/*
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            {/ * <Card.Img variant="top" style={style_image} src={photo_q} /> * /}
            <p style={{textAlign: "center", fontSize: 140}}>?</p>
            <Card.Title style={style_title}>Is this you?<br/><br/></Card.Title>
            {/ * <span class="material-icons-outlined">question_mark</span> * /}
            {/ * <Card.Img variant="top" style={style_image} src="question_mark" /> * /}
            {/ * <Card.Img><span class="material-symbols-outlined">question_mark</span></Card.Img> * /}
            <Card.Title style={style_title}>Wanted non-tech and tech co-founders (especially mechanical engineering, additive manufacturing and AI)</Card.Title>
          </Card.Body>
        </Card>
        */}
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_roman} />
            <Card.Title style={style_title}>
              <a style={style_link} href="https://www.linkedin.com/in/romankuzmenko/">Roman Kuzmenko</a>
            </Card.Title>
            <Card.Text style={style_text}>
              Founder<br/>CEO
            </Card.Text>
          </Card.Body>
        </Card>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_victor} />
            <Card.Title style={style_title}>
              <a style={style_link} href="https://www.linkedin.com/in/victor-de-wansa-02a59872/">Victor De Wansa</a>
            </Card.Title>
            <Card.Text style={style_text}>
              Advisor<br/>AI/ML
            </Card.Text>
          </Card.Body>
        </Card>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_anton} />
            <Card.Title style={style_title}>
              <a style={style_link} href="https://www.linkedin.com/in/antonseidler/">Anton Seidler</a>
            </Card.Title>
            <Card.Text style={style_text}>
              Advisor<br/>Business
            </Card.Text>
          </Card.Body>
        </Card>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_denis} />
            <Card.Title style={style_title}>
              <a style={style_link} href="https://www.instagram.com/isendman/">Denis Alkhazov</a>
            </Card.Title>
            <Card.Text style={style_text}>
              Advisor<br/>Business
            </Card.Text>
          </Card.Body>
        </Card>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_laert} />
            <Card.Title style={style_title}>
              <a style={style_link} href="https://www.linkedin.com/in/lkaraashev/">Laert Karaashev</a>
            </Card.Title>
            <Card.Text style={style_text}>
              Advisor<br/>Finances
            </Card.Text>
          </Card.Body>
        </Card>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_noman} />
            <Card.Title style={style_title}>
              <a style={style_link} href="https://www.linkedin.com/in/noman-ahmed-b282294/">Noman Ahmed</a>
            </Card.Title>
            <Card.Text style={style_text}>
              Advisor<br/>Design and Manufacturing
            </Card.Text>
          </Card.Body>
        </Card>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_shehab} />
            <Card.Title style={style_title}>
              <a style={style_link} href="https://www.linkedin.com/in/shehabattia/">Shehab Attia</a>
            </Card.Title>
            <Card.Text style={style_text}>
              Advisor<br/>CAD
            </Card.Text>
          </Card.Body>
        </Card>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_pavel} />
            <Card.Title style={style_title}>
              <a style={style_link} href="https://www.linkedin.com/in/pavel-khijniak/">Pavel Khijniak</a>
            </Card.Title>
            <Card.Text style={style_text}>
              Advisor<br/>CAD and Robotics
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
      {/*</Container>*/}
    </div>
  );
}

export default About;
